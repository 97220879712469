@use "../../styles/mixins" as *;
@include layer(ui) {
    .input-radio {
        &:active + .indicator,
        &:hover + .indicator {
            border-color: var(--s-color-field-border-active);
            border-width: var(--s-border-width-field-active);
        }

        &:focus-visible + .indicator {
            border-color: var(--s-color-field-border-focus);
            border-width: var(--s-border-width-field-focus);
            outline-color: var(--s-color-field-border-focus);
            outline-offset: var(--g-spacing-xxs);
            outline-style: solid;
            outline-width: var(--g-border-width-sm);
        }

        &[aria-disabled="true"] + .indicator {
            border-color: var(--s-color-disabled-primary);
        }

        &:checked {
            & + .indicator {
                align-items: center;
                border-color: var(--s-color-field-border-default);
                color: var(--s-color-selection-background-selected-bold);
                display: flex;
                justify-content: center;

                svg {
                    visibility: visible;
                    transform: translate(0, 0); // fix safari positioning
                }
            }

            &[aria-disabled="true"] + .indicator {
                color: var(--s-color-disabled-primary);
                border-color: var(--s-color-disabled-primary);
            }
        }
    }

    .indicator-wrapper {
        align-self: self-start;
        display: inline-flex;
        height: 1lh;
    }
    .radio-body {
        display: flex;
        align-items: flex-start;
        gap: var(--g-spacing-md);
    }
    .indicator {
        --outer-circle-size: var(--s-size-height-field-small);
        --outer-circle-fill-padding: calc(var(--g-spacing-xs));
        --inner-circle-size: calc(
            var(--outer-circle-size) -
                calc(var(--outer-circle-fill-padding) * 2)
        );

        align-items: center;
        border-radius: 100%;
        border-color: var(--s-color-field-border-default);
        border-style: solid;
        border-width: var(--s-border-width-field-default);
        box-sizing: border-box;
        display: inline-grid;
        justify-content: center;
        height: var(--outer-circle-size);
        width: var(--outer-circle-size);
        margin-top: var(--c-radio-circle-top-padding);

        svg {
            color: currentColor;
            height: var(--inner-circle-size);
            width: var(--inner-circle-size);
            visibility: hidden;
        }

        circle {
            fill: currentColor;
        }
        &--error {
            border-color: var(--s-color-field-border-error);
        }
    }

    .label-wrapper {
        color: var(--s-color-text-primary);
        cursor: pointer;
        display: flex;
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-regular);
        justify-content: space-between;
        line-height: var(--g-line-height-400);
        position: relative;

        &--disabled {
            &,
            .required-marker {
                color: var(--s-color-disabled-primary);
            }
        }

        &:hover,
        &:active {
            .input-checkbox:not(:checked):not([aria-disabled="true"])
                + .indicator {
                border-color: var(--s-color-field-border-active);
                border-width: var(--s-border-width-field-active);
            }
        }

        &--detail-right {
            gap: var(--g-spacing-md);
        }

        &--detail-below {
            flex-direction: column;
            gap: var(--g-spacing-xxs);
        }
    }

    .label-text--required {
        margin-right: var(--g-spacing-sm);
    }

    .label-detail {
        font-weight: var(--g-font-weight-light);
        color: var(--s-color-text-primary);

        &--right {
            text-align: right;
        }
        padding-left: calc(
            var(--s-size-height-field-small) + var(--g-spacing-md)
        );
    }

    .required-marker {
        color: var(--s-color-text-quiet);
    }

    .description-wrapper {
        margin-left: calc(
            var(--s-size-height-field-small) + var(--g-spacing-md)
        );
        margin-top: var(--g-spacing-xxs);

        & > * {
            margin: 0;
        }

        &--disabled * {
            color: var(--s-color-disabled-primary);
        }
    }
}
