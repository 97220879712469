@use "../../styles/mixins" as *;
@include layer(ui) {
    .container {
        border-radius: 100%;
        font-size: var(--g-size-xxs);
        stroke-width: var(--c-icon-button-icon-stroke-width);
        display: flex;
        align-items: center;
        justify-content: center;

        &--primary {
            &--size-standard {
                height: var(--c-icon-button-primary-standard-height);
                width: var(--c-icon-button-primary-standard-height);
            }

            &--size-small {
                height: var(--c-icon-button-primary-small-height);
                width: var(--c-icon-button-primary-small-height);
            }

            transition: none var(--g-duration-xshort) ease;
            transition-property: color, filter;
            box-sizing: border-box;
            border-style: solid;
            border-width: var(--c-icon-button-primary-border-width);
            border-color: transparent;
            background-color: var(--s-color-surface-secondary);
            box-shadow: (var(--s-shadow-standard-default));
            color: var(--icon-color-default);
            cursor: pointer;

            &:hover:not(:disabled) {
                box-shadow: (var(--s-shadow-standard-active));
                color: var(--icon-color-active);
            }

            &:focus-visible:not(:disabled) {
                transition: none;
                outline-style: solid;
                outline-width: var(--g-border-width-sm);
                outline-color: var(--outline-color-focus);
                outline-offset: var(
                    --c-icon-button-primary-outline-offset-focus
                );
                box-shadow: (var(--s-shadow-standard-active));
                color: var(--icon-color-active);
            }

            &:active:not(:disabled) {
                transform: scale(0.96);
            }

            &:disabled:not([data-loading="true"]) {
                cursor: not-allowed;
                color: var(--s-color-disabled-primary);
                filter: none;
                box-shadow: none;
            }

            &[data-loading="true"] {
                cursor: not-allowed;
            }
        }

        &--secondary {
            &--size-standard {
                height: var(--c-icon-button-secondary-standard-height);
                width: var(--c-icon-button-secondary-standard-height);
            }

            &--size-small {
                height: var(--c-icon-button-secondary-small-height);
                width: var(--c-icon-button-secondary-small-height);
            }

            transition: none var(--g-duration-xshort) ease;
            transition-property: color, background-color, border-color;
            border-style: solid;
            border-width: var(--c-icon-button-secondary-border-width);
            border-color: var(--border-color-default);
            background-color: transparent;
            color: var(--icon-color-default);
            cursor: pointer;

            &:hover:not(:disabled) {
                background-color: var(--background-color-active);
                border-color: var(--border-color-active);
                color: var(--icon-color-active);
            }

            &:focus-visible:not(:disabled) {
                transition: none;
                outline-style: solid;
                outline-offset: var(--g-spacing-xxs);
                outline-color: var(--outline-color-focus);
                outline-width: var(--g-border-width-sm);
                background-color: var(--background-color-active);
                border-color: var(--border-color-active);
                color: var(--icon-color-active);
            }

            &:active:not(:disabled) {
                transform: scale(0.96);
            }

            &:disabled:not([data-loading="true"]) {
                cursor: not-allowed;
                border-color: var(--s-color-disabled-primary);
                color: var(--s-color-disabled-primary);
            }

            &[data-loading="true"] {
                cursor: not-allowed;
            }
        }
    }
}
