@use "@wojo/ui/mixins" as *;

.container {
    background-color: var(--s-color-surface-primary);
    border-radius: var(--s-border-radius-background-standard);
    margin-top: var(--g-spacing-md);
    padding: var(--g-spacing-lg);
    @include breakpoint-lg {
        padding: var(--g-spacing-xl);
    }
}

.spinner {
    display: flex;
    justify-content: center;
}
