@use "@wojo/ui/mixins" as *;

.tour-hero-summary-wrapper {
    display: grid;
    row-gap: var(--g-spacing-md);
    align-content: start;
    height: 100%;
    padding-left: var(--g-spacing-lg);
    padding-right: var(--g-spacing-lg);
    @include breakpoint-sm {
        padding-left: 0;
        padding-right: 0;
    }
    @include breakpoint-md {
        row-gap: var(--g-spacing-lg);
        padding-bottom: var(--g-spacing-lg);
    }
    @include breakpoint-lg {
        row-gap: var(--g-spacing-xl);
        padding-bottom: var(--g-spacing-xl);
    }
}
.tour-hero-summary-section-wrapper {
    display: grid;
    column-gap: var(--g-spacing-xl);
    grid-template-columns: auto;
    justify-content: stretch;
    align-items: center;
    @include breakpoint-md {
        justify-content: start;
    }
    @include breakpoint-xl {
        grid-template-columns: auto auto;
    }
    row-gap: var(--g-spacing-xl);
}
.heading {
    padding-bottom: var(--g-spacing-sm);
    padding-top: var(--g-spacing-sm);

    @include breakpoint-md {
        padding-top: var(--g-spacing-md);
    }
    @include breakpoint-xl {
        padding-top: var(--g-spacing-xl);
    }
}
