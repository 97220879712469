.heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    svg {
        flex-shrink: 0;
        margin-left: var(--g-spacing-xs);
    }
}

.inclusion {
    text-align: left;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
}

.button {
    margin-top: var(--g-spacing-sm);
}

.description {
    margin-top: var(--g-spacing-xs);
}
