@use "@wojo/ui/mixins" as *;

.marketing-text-card {
    margin-top: var(--g-spacing-xl);
    margin-bottom: var(--g-spacing-xl);

    &--non-primary {
        @include breakpoint-md {
            margin-bottom: var(--g-spacing-xxl);
        }
    }

    & > * {
        padding-left: 0;
        padding-right: 0;
    }
}
