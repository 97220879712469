@use "@wojo/ui/mixins" as *;

.tour-hero-summary-buttons-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    align-content: end;
    justify-content: start;
    column-gap: var(--g-spacing-md);
    padding-bottom: var(--g-spacing-xs);
    @include breakpoint-lg {
        padding-bottom: 0;
    }
}
