@use "@wojo/ui/mixins" as *;

.dropdown {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    background-color: var(--s-color-surface-secondary);
    padding-top: var(--g-spacing-md);
    padding-bottom: var(--g-spacing-md);
    box-shadow: var(--s-shadow-down);
    z-index: 3;
    display: flex;
    margin-top: var(--g-spacing-sm);
    border-top: var(--g-border-width-xs) solid var(--s-color-separator-standard);
}
.suggested {
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr;
    padding: var(--g-spacing-md) 0;
    border-bottom: 1px solid var(--s-color-separator-standard);
    column-gap: var(--g-spacing-sm);
    outline: var(--g-border-width-sm) solid transparent;

    text-decoration: none;
    cursor: pointer;
    transition-duration: var(--g-duration-xshort);
    transition-property: filter;
    transition-timing-function: ease;
    em {
        font-weight: var(--g-font-weight-bold);
        font-style: normal;
    }
    @media (prefers-reduced-motion: reduce), (forced-colors: active) {
        transition: none;
    }

    &:hover,
    &:active {
        color: var(--s-color-brand-primary-standard);
        border-radius: var(--s-border-radius-interactive-focus);
        text-decoration: underline;
    }

    &:focus-visible {
        outline: var(--g-border-width-sm) solid
            var(--s-color-brand-primary-standard);
        transition: none;
        color: var(--s-color-brand-primary-standard);
        box-shadow: (var(--s-shadow-standard-active));
        border-radius: var(--s-border-radius-interactive-focus);
        text-decoration: underline;
    }
}
.tours {
    display: grid;
    grid-template-columns: auto;
    align-content: start;
    align-items: center;
    row-gap: var(--g-spacing-md);
}
