@use "@wojo/ui/mixins" as *;
.tour-hero {
    padding: var(--g-spacing-md) 0;
    background-image: var(--hero-background-image);

    @include breakpoint-lg {
        padding: var(--g-spacing-xxs) 0 var(--g-spacing-md) 0;
    }
}
.tour-hero-container {
    @include breakpoint-lg {
        padding-bottom: 0;
    }
}
.see-all-button {
    position: absolute;
    z-index: 2;
    &-mobile {
        display: block;
        @include breakpoint-sm {
            display: none;
        }
        bottom: var(--g-spacing-sm);
    }
    &-desktop {
        display: none;
        @include breakpoint-sm {
            display: block;
        }
        bottom: var(--g-spacing-md);
        right: var(--g-spacing-md);
    }
}

.badge {
    position: absolute;
    top: var(--g-spacing-sm);
    left: var(--g-spacing-sm);
    z-index: 1;
    @include breakpoint-lg {
        top: var(--g-spacing-md);
        left: var(--g-spacing-md);
    }
}
