@use "../../styles/mixins" as *;
@include layer(ui) {
    .loading-animation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--g-spacing-sm);

        &-lottie-wrapper {
            aspect-ratio: 1 / 1.44;
            position: relative;
            width: var(--width, 100px);
        }

        &-lottie {
            display: flex;
            position: relative;
            width: var(--width, 100px);
            z-index: 1;
        }

        &-placeholder {
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 0;

            svg {
                width: 100%;
            }
        }

        &-text {
            font-family: var(--g-font-family-primary);
            font-weight: var(--g-font-weight-light);
            font-size: var(--g-font-size-400);
            line-height: var(--g-line-height-400);
            color: var(--s-color-text-primary);
            align-items: center;
            display: flex;
            text-align: center;
            flex-direction: column;
            margin: 0;
        }
    }

    .initial-state {
        opacity: 0;
    }

    .fade-in {
        opacity: 0;
        animation: fade-in-animation var(--g-duration-xshort);
        animation-fill-mode: forwards;
    }

    .fade-out {
        opacity: 1;
        animation: fade-out-animation var(--g-duration-xshort);
        animation-delay: var(--g-duration-xlong);
        animation-fill-mode: forwards;
    }

    @keyframes fade-in-animation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fade-out-animation {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}
