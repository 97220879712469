@use "@wojo/ui/mixins" as *;

.banner {
    margin-bottom: var(--g-spacing-lg);
    a {
        color: inherit;
    }
}

.modal-content {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
}

.cancelled-banner {
    display: flex;
    margin-bottom: var(--g-spacing-lg);
}
