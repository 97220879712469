@use "@wojo/ui/mixins" as *;

.wrapper {
    padding-left: var(--g-spacing-md);
    padding-right: var(--g-spacing-md);

    @include breakpoint-sm {
        padding-left: 0;
        padding-right: 0;
    }
}

.breadcrumbs {
    margin-top: 0;
    margin-bottom: 0;
}
