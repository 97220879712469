@use "../../styles/mixins" as *;
@mixin center-content {
    display: flex;
    place-items: center;
    place-content: center;
}
@include layer(ui) {
    .search-field-wrapper {
        border-radius: var(--c-search-field-border-radius);

        &:not(:focus-within) {
            .clear-button {
                display: none;
            }
        }

        button {
            all: unset;
            height: 40px;
            width: 40px;
            @include center-content;
            cursor: pointer;
        }

        svg {
            width: var(--g-size-xxs);
            height: var(--g-size-xxs);
            stroke-width: var(--c-search-field-icon-stroke-width);
        }

        &--standard {
            background: var(--s-color-surface-secondary);
            min-height: var(--c-search-field-standard-height);
            padding-right: var(--c-search-field-standard-padding-right);
            box-shadow: (var(--c-search-field-standard-shadow));

            &--disabled {
                filter: none;
                background: transparent;
            }

            .vertical-separator {
                display: var(--c-search-field-standard-separator-display);
            }
        }

        &--quiet {
            min-height: var(--c-search-field-quiet-height);
            padding-right: var(--c-search-field-quiet-padding-right);
            background: transparent;

            .search-button {
                height: 100%;
                background: var(--background-default);
                border: var(--g-border-width-xs) solid
                    var(--border-color-default);

                @media (forced-colors: active) {
                    border: none;
                }
            }

            .clear-button {
                height: 100%;
                background-color: transparent;
                border: var(--g-border-width-xs) solid
                    var(--border-color-default);
                border-right: 0;

                @media (forced-colors: active) {
                    border-left: none;
                }
            }

            .vertical-separator {
                display: var(--c-search-field-quiet-separator-display);
            }

            & > :last-child {
                margin-right: 0;
            }
        }
    }

    .search-field {
        display: flex;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &::placeholder {
            color: var(--s-color-text-quiet);
            font-family: var(--g-font-family-primary);
            font-weight: var(--g-font-weight-regular);
            font-size: var(--g-font-size-400);
            line-height: var(--g-line-height-400);
            font-style: var(--c-search-field-placeholder-text-font-style);
        }

        /* clears the ‘X’ from Internet Explorer */
        &::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }

        &::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
        }

        /* clears the ‘X’ from Chrome */
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    }

    .adorn-right {
        @include center-content;
        color: var(--icon-color-default);

        svg {
            color: currentColor;
        }

        margin-right: calc(var(--g-spacing-md) * -1);

        &--disabled {
            color: var(--s-color-disabled-primary);
        }

        &--standard {
            height: var(--s-size-height-field-standard);
        }

        &--quiet {
            height: var(--c-search-field-quiet-height);
            margin-right: -1px;

            @media (forced-colors: active) {
                margin-right: 0;

                .vertical-separator {
                    display: flex;
                }

                .search-button,
                .clear-button {
                    border-left-color: transparent;
                }
            }

            &--disabled {
                .search-button,
                .clear-button {
                    background: transparent;
                    border: var(--g-border-width-xs) solid
                        var(--border-color-disabled);
                    cursor: not-allowed;

                    @media (forced-colors: active) {
                        border-top: none;
                        border-right: none;
                        border-bottom: none;
                    }
                }

                .clear-button {
                    border-right: none;
                }
            }
        }
    }

    .adorn-left {
        @include center-content;
        color: var(--s-color-icon-primary);

        &--disabled {
            color: var(--s-color-disabled-primary);
        }

        svg {
            color: currentColor;
        }
    }

    .vertical-separator {
        height: var(--g-size-xs);
        border-left: 0;
        border-bottom: 0;
        border-top: 0;
        border-right: var(--g-border-width-xs) solid
            var(--s-color-separator-standard);
        margin: 0 var(--g-spacing-xxs);

        *:not(.clear-button) + &,
        .search-field-wrapper:not(:focus-within) & {
            display: none;
        }
    }

    .search-button:focus-visible:not(:disabled),
    .clear-button:focus-visible:not(:disabled),
    .loading-spinner:focus-visible:not(:disabled) {
        outline-width: var(--g-border-width-sm);
        outline-color: var(--outline-color-focus);
        --focus-offset: calc(var(--g-border-width-sm) + var(--g-spacing-xs));
        outline-offset: calc(var(--focus-offset) * -1);

        outline-style: solid;
        border-radius: var(
            --c-search-field-icon-button-outline-border-radius-focus
        );

        color: var(--icon-color-focus);
    }
}
