@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .container {
        margin-bottom: var(--g-spacing-md);

        @include breakpoint-lg {
            margin: var(--g-spacing-md) 0;
        }

        width: 100%;
        > ol {
            display: inline-block;
            flex-wrap: nowrap;
            white-space: nowrap;
            margin: 0;
            padding-left: 0;
        }
    }

    .breadcrumb-item {
        display: inline-flex;
        white-space: nowrap;
        align-items: center;
        cursor: pointer;
        position: relative;

        &:focus-visible,
        button:focus-visible {
            outline-offset: var(--g-spacing-xxs);
            outline: var(--s-color-brand-primary-standard) solid
                var(--g-border-width-sm);
        }

        .icon-wrapper {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 16px;
            width: 16px;
            svg {
                margin-top: 2px;
                font-size: var(--g-font-size-100);
                stroke-width: 1.5px;
            }
        }
    }

    .link {
        &--more {
            // clear browser button styles
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
        }

        &--current {
            max-width: var(--max-width, 100%);
            display: inline-block;
            cursor: default;
        }

        a,
        & {
            text-decoration: underline;
            color: var(--s-color-text-primary);
            font-family: var(--g-font-family-primary);
            font-size: var(--g-font-size-300);
            line-height: var(--g-line-height-300);
            font-weight: var(--g-font-weight-regular);
            margin: 0 var(--g-spacing-xs);

            &:hover:not(.link--current) {
                color: var(--s-color-brand-primary-standard);
            }

            &:focus-visible {
                outline-offset: var(--g-spacing-xxs);
                outline: var(--s-color-brand-primary-standard) solid
                    var(--g-border-width-sm);
                color: var(--s-color-brand-primary-standard);
                border-radius: var(--c-breadcrumb-outline-border-radius-focus);
            }

            &:last-child {
                text-decoration: none;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                min-width: 8ch;
            }
        }
    }

    .dropdown-menu {
        display: none;
        position: absolute;
        z-index: 2;
        margin-top: var(--g-spacing-sm);
        top: 16px;
        left: -60px;
        flex-direction: column;
        box-sizing: border-box;
        min-width: 212px;
        padding: var(--g-spacing-sm) var(--g-spacing-md);
        width: fit-content;
        background-color: var(--s-color-surface-secondary);
        border-radius: var(--s-border-radius-background-small);
        box-shadow: (var(--s-shadow-standard-default));
        border: var(--g-border-width-xs) solid transparent;
        transition-duration: var(--g-duration-xshort);
        transition-timing-function: ease;
        transition-property: background-color, border-radius, filter, border,
            transform;
        max-width: calc(100vw - var(--g-spacing-xxl));

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
        > li {
            padding: var(--g-spacing-md);
            border-bottom: 1px solid var(--s-color-separator-standard);
            &:last-child {
                border-bottom: none;
            }
        }
        .dropdown-item {
            a {
                white-space: break-spaces;
                overflow: auto;
                inline-size: max-content;
            }
        }
        hr {
            width: 100%;
            border: none;
            height: 1px;
            background: var(--s-color-separator-standard);
        }
        &--open {
            display: flex;
        }
    }
}
