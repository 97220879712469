@use "../../styles/mixins" as *;
@include layer(ui) {
    @keyframes animation-modal-content {
        0% {
            opacity: 0;
            transform: translateY(-100px);
        }

        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    @keyframes animation-modal-content-no-translate {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes animation-modal-overlay {
        0% {
            background-color: rgba(0, 0, 0, 0);
        }

        100% {
            background-color: rgba(0, 0, 0, 0.4);
        }
    }

    .modal {
        padding: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 9999999999; // drift chat has a ridiculous z-index and we want modals to be above it
        animation: var(--g-duration-xshort) ease-out animation-modal-overlay;

        @media (prefers-reduced-motion: reduce) {
            animation: none;
        }

        &--overlay {
            @include for-tablet-up {
                padding: var(--g-spacing-xl) 0;
                overflow: auto;

                .content {
                    outline: var(--s-border-width-background-standard) solid
                        transparent;
                    border-radius: var(--s-border-radius-background-standard);
                    max-width: 500px;
                    height: auto;
                    margin: auto;
                }
            }
        }

        &--fullscreen {
            @include for-tablet-up {
                .content {
                    overflow: auto;
                    width: 100%;
                    height: 100%;
                    border-radius: 0;
                }
            }
        }

        &--open {
            display: flex;
        }
    }

    .content {
        color: var(--s-color-text-primary);
        box-sizing: border-box;
        display: block;
        padding: 0;
        overflow: auto;
        position: relative;
        border: 0;
        width: 100%;
        height: 100%;
        background-color: var(--s-color-surface-secondary);
        padding-top: var(--g-spacing-lg);
        animation: var(--g-duration-xshort) ease-out animation-modal-content;

        @media (prefers-reduced-motion: reduce) {
            animation: none;
        }

        @include for-tablet-up {
            height: 100%;
            padding-top: var(--g-spacing-xl);
        }

        &--disable-modal-translate {
            animation: var(--g-duration-xshort) ease-out
                animation-modal-content-no-translate;
        }
    }

    .header,
    .body,
    .footer {
        padding-left: var(--g-spacing-lg);
        padding-right: var(--g-spacing-lg);

        @include for-tablet-up {
            padding-left: var(--g-spacing-xl);
            padding-right: var(--g-spacing-xl);
        }
    }

    .header {
        padding-bottom: var(--g-spacing-md);
        display: grid;
        grid-template-columns: auto auto;
        grid-template-areas: "left right";
        gap: 0 minmax(var(--g-spacing-md), auto);
        justify-content: space-between;
        align-items: center;

        &__title {
            display: contents;
        }
    }

    .close {
        grid-area: right;
    }

    .body {
        padding-bottom: var(--g-spacing-lg);
        font-family: var(--g-font-family-primary);
        letter-spacing: var(--g-letter-spacing-standard);

        @include for-tablet-up {
            padding-bottom: var(--g-spacing-xl);
        }
    }

    .footer {
        padding-top: var(--c-modal-footer-padding-vertical);
        padding-bottom: var(--c-modal-footer-padding-vertical);
        border-top: var(--g-border-width-xs) solid
            var(--s-color-separator-standard);
    }

    .align {
        &--right,
        &--between {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: var(--g-spacing-md);
        }

        &--right {
            @include for-tablet-up {
                flex-direction: row;
                justify-content: flex-end;
            }
        }

        &--between {
            @include for-tablet-up {
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}
