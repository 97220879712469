@use "@wojo/ui/mixins" as *;
.collage {
    width: 100%;
    display: grid;
    gap: var(--g-spacing-sm);
    grid:
        "media1 media2" 150px
        "media1 media3" 150px
        "content content" auto
        / 2fr 1fr;
    @include breakpoint-sm {
        grid:
            "media1 media2 media3" 180px
            "media1 media4 media4" 197px
            "content content content" auto
            / 47fr 27fr 26fr;
    }
    @include breakpoint-md {
        grid:
            "media1 media2 media3" 230px
            "media1 media4 media4" 258px
            "content content content" auto
            / 54fr 23fr 23fr;
    }
    @include breakpoint-lg {
        grid:
            "media1 content content content" 3fr
            "media1 media2 media3 media4" 2fr
            / 40fr 20fr 15fr 25fr;
        height: 550px;
    }
    @include breakpoint-xl {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 32fr 22fr 15fr 31fr;
    }
}
.collage-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
    transition: all var(--g-duration-xshort);
    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }
    overflow: hidden;
    &:hover {
        cursor: pointer;
        transform: scale(1.025); // Scale the image on hover 2.5%
    }
}
.content {
    grid-area: content;
    position: relative;
    @include breakpoint-lg {
        margin-left: var(--g-spacing-xl);
    }
}
.media1 {
    grid-area: media1;
    position: relative;
    overflow: hidden;
    height: 100%;
    &:focus-within {
        outline: var(--s-border-width-field-focus) solid
            var(--s-color-disabled-primary);
    }
}
.media2 {
    grid-area: media2;
    position: relative;
    overflow: hidden;
    &:focus-within {
        outline: var(--s-border-width-field-focus) solid
            var(--s-color-disabled-primary);
    }
}
.media3 {
    grid-area: media3;
    position: relative;
    overflow: hidden;
    justify-items: center;
    display: grid;
    &:focus-within {
        outline: var(--s-border-width-field-focus) solid
            var(--s-color-disabled-primary);
    }
}
.media4 {
    grid-area: media4;
    position: relative;
    overflow: hidden;
    display: none;
    @include breakpoint-sm {
        display: inherit;
    }
    &:focus-within {
        outline: var(--s-border-width-field-focus) solid
            var(--s-color-disabled-primary);
    }
}
