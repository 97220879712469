@use "../../styles/mixins" as *;
@include layer(ui) {
    .group-wrapper {
        border: 0;
        margin: 0;
        padding: 0;
    }

    .group-intro {
        margin-bottom: var(--g-spacing-md);
    }

    .group-title {
        align-items: baseline;
        color: var(--s-color-text-primary);
        display: flex;
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-300);
        font-weight: var(--g-font-weight-regular);
        gap: var(--g-spacing-sm);
        line-height: var(--g-line-height-300);
        padding: 0;

        &--error {
            color: var(--s-color-alert-standard);
            margin-bottom: 0;
        }
    }

    .required-marker {
        color: var(--s-color-text-quiet);
    }

    .error-wrapper {
        align-items: center;
        color: var(--s-color-alert-standard);
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-200);
        font-weight: var(--g-font-weight-regular);
        display: flex;
        gap: var(--g-spacing-sm);
        line-height: var(--g-line-height-200);
        margin-bottom: var(--g-spacing-md);
        margin-top: var(--g-spacing-xs);
    }

    .error-icon {
        color: currentColor;
        font-size: var(--g-size-xxs);
    }

    .group-row {
        padding-bottom: var(--g-spacing-md);

        &:last-of-type {
            padding: 0;
        }

        &--with-separator {
            border-bottom-color: var(--s-color-separator-standard);
            border-bottom-style: solid;
            border-bottom-width: var(--g-border-width-xs);
            margin-bottom: var(--g-spacing-md);

            &:last-of-type {
                border: none;
                margin: 0;
            }
        }
    }

    .group-column {
        margin-bottom: var(--g-spacing-md);

        @include breakpoint-md {
            display: inline-grid;
            grid-template-columns: auto 1fr;
            grid-column-gap: var(--g-spacing-md);
        }

        &:last-of-type {
            padding: 0;
        }

        &--with-separator {
            @include breakpoint-xs {
                padding-bottom: var(--g-spacing-md);
                border-bottom-color: var(--s-color-separator-standard);
                border-bottom-style: solid;
                border-bottom-width: var(--g-border-width-xs);
                margin-bottom: var(--g-spacing-md);
            }

            @include breakpoint-md {
                border-bottom: 0;
                padding-bottom: 0;
                border-right-color: var(--s-color-separator-standard);
                border-right-style: solid;
                border-right-width: var(--g-border-width-xs);
                margin-right: var(--g-spacing-md);
            }

            &:last-of-type {
                border: none;
                margin: 0;
            }
        }
    }
}
