@use "../../styles/mixins" as *;
@include layer(ui) {
    .input-action {
        text-overflow: ellipsis;
        overflow: hidden;
        min-height: inherit;
    }
    .wrapper {
        font-family: var(--g-font-family-primary);
    }
    .toggle-button {
        display: grid;
        background-color: transparent;
        outline: none !important;
        padding: 0;
        border: 0;
        cursor: pointer;
        color: var(--icon-color-default);
        &:focus {
            color: var(--icon-color-focus);
            outline: none;
        }
        &:active:not(:disabled),
        &:hover:not(:disabled) {
            color: var(--icon-color-active);
        }
    }
    .menu {
        position: relative;

        &--with-label {
            padding-top: var(--g-spacing-xs);
        }
    }
}
