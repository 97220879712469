@use "@wojo/ui/mixins" as *;

.card {
    justify-content: start;
    padding: var(--g-spacing-xl) var(--g-spacing-xl) var(--g-spacing-lg);
    @include breakpoint-md {
        padding: var(--g-spacing-xl) var(--g-spacing-xl) var(--g-spacing-md);
    }
    display: grid;
    margin-top: var(--g-spacing-xl);
    margin-bottom: var(--g-spacing-xl);
}

.column {
    gap: var(--g-spacing-xl);
}
