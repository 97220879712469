.header {
    padding-top: var(--g-spacing-md);
    padding-bottom: var(--g-spacing-md);
    background-color: var(--s-color-surface-primary);
    margin-bottom: var(--g-spacing-xl);
}

.footer {
    display: flex;
    justify-content: flex-start;
}
