//TODO: Add better hierarchy for our components WWB-768
@use "../../../styles/mixins" as *;
@include layer(textbox) {
    .container {
        display: flex;
        margin: 0;
        min-height: var(--s-size-height-field-standard);
        appearance: none;
        align-items: center;
        box-sizing: border-box;
        border-radius: var(--s-border-radius-field-standard);
        border-width: var(--s-border-width-field-default);
        border-style: solid;
        border-color: var(--s-color-field-border-default);
        ::placeholder {
            color: var(--s-color-text-quiet);
        }
        &--focused {
            outline-width: var(--s-border-width-field-focus);
            outline-color: var(--s-color-field-border-active);
            border-color: var(--s-color-field-border-active);
            color: var(--s-color-text-primary);
            outline-style: solid;
        }

        &--not-disabled {
            &:hover:not(:focus-within) {
                border-width: var(--s-border-width-field-active);
                border-color: var(--s-color-field-border-active);
                color: var(--s-color-text-primary);
                outline-style: none;
            }
        }
        &--disabled {
            border-color: var(--s-color-disabled-primary);
            color: var(--s-color-disabled-primary);
            ::placeholder {
                color: var(--s-color-disabled-primary);
            }
            &:focus-within {
                outline: var(--s-border-width-field-focus) solid
                    var(--s-color-disabled-primary);
            }
        }
        &--error {
            border-color: var(--s-color-field-border-error);
            color: var(--s-color-field-border-error);
        }
    }
    .input {
        border-radius: var(--s-border-radius-field-standard);
        padding: 0 var(--g-spacing-md);
        background-color: transparent;
        border: 0;
        height: 100%;
        width: 100%;
        outline: none !important;
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-regular);
        font-size: var(--g-font-size-400);
        line-height: var(--g-line-height-400);
        color: var(--s-color-text-primary);
        &--has-left {
            padding-left: 0;
        }
        &--has-right {
            padding-right: 0;
        }
    }
    .disabled {
        border-color: var(--s-color-disabled-primary);
        color: var(--s-color-disabled-primary);
        cursor: not-allowed;
    }
    .adornment {
        display: inline-flex;
        align-items: center;
        box-sizing: border-box;
        &--left {
            margin: 0 var(--g-spacing-sm) 0 var(--g-spacing-md);
        }
        &--right {
            margin: 0 var(--g-spacing-md) 0 var(--g-spacing-sm);
        }
    }
}
