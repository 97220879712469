.heading {
    margin-top: var(--g-spacing-sm);
}

.title {
    margin-top: var(--g-spacing-lg);
}

.sherpaLink {
    align-items: center;
    display: inline-flex;
    img {
        height: 15px;
    }
    span {
        align-items: center;
        border: 1px solid #acadac;
        border-radius: 8px;
        display: inline-flex;
        height: 40px;
        margin-top: var(--g-spacing-sm);
        padding: 0 var(--g-spacing-md);
    }
}

.description {
    max-width: 704px;
    margin: var(--g-spacing-md) 0;
}

.notFound {
    margin-top: var(--g-spacing-sm);
}

.helpCenterButton {
    margin-top: var(--g-spacing-md);
}
