.title {
    margin-bottom: var(--g-spacing-xs);
}

.price {
    margin-bottom: var(--g-spacing-md);
}

.image {
    max-width: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.description {
    margin-top: var(--g-spacing-md);
}
