@use "@wojo/ui/mixins" as *;

.image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}
.title {
    margin-bottom: var(--g-spacing-sm);
}
.per-person {
    display: inline-flex;
    gap: var(--g-spacing-sm);
    flex-wrap: wrap;
    align-items: flex-start;
    @include breakpoint-md {
        flex-direction: column;
        gap: 0;
    }
}
