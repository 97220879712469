.title {
    margin-bottom: var(--g-spacing-sm);
}

.description {
    margin-bottom: var(--g-spacing-lg);
}

.excursions {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
    list-style: none;
    padding: 0;
}
