@use "@wojo/ui/mixins" as *;

.table {
    text-align: left;
    border-collapse: collapse;
    position: relative;
    width: 100%;
    contain: content;
}

.header {
    background-color: var(--s-color-surface-secondary);
    position: sticky;
    top: -1px; //account for 1px gap
    z-index: 1;
}

.table-heading {
    padding: var(--g-spacing-sm) var(--g-spacing-md);
    text-align: center;
    vertical-align: bottom;
    white-space: nowrap;
}

.year-heading {
    background-color: var(--s-color-surface-quiet);
    position: sticky;
    top: 0;
    z-index: 1;
    outline: 1px solid transparent;
}

.table-cell {
    padding: var(--g-spacing-md);
    vertical-align: top;
    &--date {
        contain: layout;
        position: relative;
    }
}
.departure-row {
    border-bottom: 1px solid var(--s-color-separator-standard);
    position: relative;
    &:last-of-type {
        border-bottom: none;
    }
}

.info-button {
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    vertical-align: bottom;
    margin-left: var(--g-spacing-xs);
    color: inherit;
}

.button-indicator {
    display: none;
    @include breakpoint-sm {
        cursor: pointer;
        background-color: transparent;
        border-radius: 50%;
        width: var(--s-size-height-field-small);
        border-color: var(--s-color-field-border-default);
        border-style: solid;
        border-width: var(--s-border-width-field-default);
        flex-shrink: 0;
        aspect-ratio: 1/1;
        display: flex;
        box-sizing: border-box;
    }
    .departure-row:hover &,
    .departure-row:active & {
        border-color: var(--s-color-field-border-focus);
        border-width: var(--s-border-width-field-focus);
    }
    .departure-row:focus-visible & {
        border-color: var(--s-color-field-border-focus);
        border-width: var(--s-border-width-field-focus);
        outline-color: var(--s-color-field-border-focus);
        outline-offset: var(--g-spacing-xxs);
        outline-style: solid;
        outline-width: var(--g-border-width-sm);
    }
}

.cta {
    background-color: transparent;
    border: none;
    padding: 0;
    text-align: left;
    &:focus-visible {
        border-radius: var(--s-border-radius-interactive-focus);
        color: var(--s-color-brand-primary-standard);
        outline-color: currentColor;
        outline-offset: var(--g-spacing-xxs);
        outline-style: solid;
        outline-width: var(--g-border-width-sm);
    }
    &::after {
        content: "";
        cursor: pointer;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
    }
}

.fallback-departure {
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        "icon line1"
        "icon line2";
    grid-column-gap: var(--g-spacing-md);
    grid-row-gap: var(--g-spacing-sm);

    &:not(:last-of-type) {
        margin-bottom: var(--g-spacing-lg);
    }

    &-icon {
        grid-area: icon;
    }

    &-line1 {
        grid-area: line1;
    }

    &-line2 {
        grid-area: line2;
    }
}
