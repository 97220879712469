@use "@wojo/ui/mixins" as *;
.card {
    display: grid;
    grid-template-columns: 1fr 2fr;
    @include breakpoint-sm {
        grid-template-columns: 185px auto;
    }
    @include breakpoint-lg {
        grid-template-columns: 1fr 3fr;
    }
    @include breakpoint-xl {
        grid-template-columns: 1fr 2fr;
    }
    height: 130px;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    @include breakpoint-lg {
        max-width: 368px;
    }
}
.media {
    overflow: hidden;
    position: relative;
    border-top-left-radius: var(--s-border-radius-background-standard);
    border-bottom-left-radius: var(--s-border-radius-background-standard);
    height: 130px;
    img {
        object-fit: cover;
    }
}
.description {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    // we need specific max width for the ellipsis to show properly
    max-width: 154px;

    @include breakpoint-sm {
        max-width: 308px;
    }
    @include breakpoint-lg {
        max-width: 154px;
    }
    @include breakpoint-xl {
        max-width: 184px;
    }
}
